import { useEffect } from "react";
import ReactGA from "react-ga4";

const Analytics = () => {
  useEffect(() => {
    ReactGA.initialize("G-18EEE9MRT5");
  }, []);
  return null;
};

export default Analytics;

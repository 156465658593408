import { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { HashLink as Hlink } from "react-router-hash-link";
import { FiArrowUpRight } from "react-icons/fi"; // Import the arrow icon
import Logo from "../media/prodlogo.png";

function Navbar() {
  const [isOpen, setIsOpen] = useState(false);
  const [textColor, setTextColor] = useState("text-white");
  const [isScrolled, setIsScrolled] = useState(false); // Scroll state

  const menuRef = useRef();
  const closeButtonRef = useRef(null);

  const toggleMenu = () => {
    setIsOpen((prev) => {
      if (prev) setTextColor("text-black");
      else setTextColor("text-white");
      return !prev;
    });
  };

  // Handle click outside the menu
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        menuRef.current &&
        !menuRef.current.contains(event.target) &&
        closeButtonRef.current &&
        !closeButtonRef.current.contains(event.target)
      ) {
        if (isOpen) {
          setIsOpen(false);
          setTextColor("text-black");
        }
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isOpen]);

  // Handle body overflow based on menu state
  useEffect(() => {
    document.body.style.overflow = isOpen ? "hidden" : "auto";
    return () => {
      document.body.style.overflow = "auto";
    };
  }, [isOpen]);

  // Handle scroll event
  useEffect(() => {
    const handleScroll = () => {
      const offset = window.scrollY;
      if (offset > 50) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  // Handle window resize event
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > 779 && isOpen) {
        setIsOpen(false); // Collapse the menu when the width is greater than 779px
      }
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [isOpen]);

  return (
    <nav
      className={`fixed top-0 left-0 w-full z-50 transition-colors duration-300 ease-in-out text-white ${
        isScrolled ? "backdrop-blur-lg" : ""
      }`}
      style={{
        background: isScrolled ? "rgba(0, 0, 0, 0.75)" : "transparent", // Change background based on scroll
        boxShadow: isScrolled ? "0 8px 32px 0 rgba(0, 0, 0, 0.75)" : "none", // Shadow based on scroll
      }}
    >
      <div className="max-w-screen-2xl flex flex-wrap items-center justify-between mx-auto p-4">
        <div className="flex items-center space-x-5 md:order-1">
          <Hlink smooth to="/#">
            <a className="flex items-center justify-end space-x-3 rtl:space-x-reverse">
              <img
                src={Logo} // Use the imported logo here
                alt="Starter 101 Logo"
                className="w-21 h-7 sm:w-36 sm:h-12 " // Adjust size as needed
              />
            </a>
          </Hlink>

          <div className={`hidden w-full md:flex md:w-auto`}>
            <ul className="flex flex-col font-medium p-4 md:p-0 mt-4 rounded-lg md:space-x-8 rtl:space-x-reverse md:flex-row md:mt-0 md:border-0 dark:bg-gray-800 md:dark:bg-gray-900 dark:border-gray-700">
              {/* <li>
                <Hlink smooth to="/#programs">
                  <a
                    className="block py-2 px text-lg rounded hover:text-transparent 
                  bg-clip-text 
                  bg-gradient-to-r 
                  from-[#bdcf32] to-[#5c8500]
                  hover:font-bold"
                  >
                    Programs
                  </a>
                </Hlink>
              </li> */}
              <li>
                <Hlink smooth to="/#how-it-works">
                  <a
                    className="block py-2 px text-lg rounded hover:text-transparent 
                  bg-clip-text 
                  bg-gradient-to-r 
                  from-[#bdcf32] to-[#5c8500]
                  hover:font-bold"
                  >
                    How it works?
                  </a>
                </Hlink>
              </li>
              <li>
                <Hlink smooth to="/#reviews">
                  <a
                    className="block py-2 px text-lg rounded hover:text-transparent 
                  bg-clip-text 
                  bg-gradient-to-r 
                  from-[#bdcf32] to-[#5c8500]
                  hover:font-bold"
                  >
                    Reviews
                  </a>
                </Hlink>
              </li>
              <li>
                <Hlink smooth to="/#faq">
                  <a
                    className="block py-2 px text-lg rounded hover:text-transparent 
                  bg-clip-text 
                  bg-gradient-to-r 
                  from-[#bdcf32] to-[#5c8500]
                  hover:font-bold"
                  >
                    FAQ
                  </a>
                </Hlink>
              </li>
            </ul>
          </div>
        </div>

        <div className="flex items-center space-x-3 md:space-x-6 md:order-2">
          <div className="font-medium hidden md:flex text-xl space-x-8 bg-gradient-to-r ">
            {/* <Hlink smooth to="/#pricing"> */}
            It's{" "}
            <strong className="text-transparent ml-1 text-xl bg-clip-text bg-gradient-to-r from-[#bdcf32] to-[#5c8500]">
              {" "}
              FREE!
            </strong>
            {/* </Hlink> */}
          </div>
          <a href="https://chromewebstore.google.com/detail/productivity-tracker/ihfjboopehfdmedfagockddklomdmglh">
            <button className="relative inline-flex h-9 sm:h-12 overflow-hidden rounded-full p-[1px] focus:outline-none  transition duration-300 ease-in-out hover:shadow-lg hover:bg-slate-800">
              <span className="absolute inset-[-1000%] animate-[spin_2s_linear_infinite] bg-[conic-gradient(from_90deg_at_50%_50%,#bdcf32_0%,#5c8500_25%,#bdcf32_50%,#5c8500_75%,#bdcf32_100%)]" />
              {/* from-[#bdcf32] to-[#5c8500] */}
              <span className="inline-flex h-full w-full cursor-pointer items-center justify-center rounded-full bg-slate-950 px-2 sm:px-3 sm:py-1 text-md sm:text-lg font-medium text-white backdrop-blur-3xl transition duration-300 ease-in-out hover:shadow-md">
                Get it NOW!{" "}
                <FiArrowUpRight className="ml-2 w-6 h-6 text-white" />
              </span>
            </button>
          </a>

          <button
            onClick={toggleMenu}
            type="button"
            className={`inline-flex items-center p-1 w-10 h-10 justify-center ${
              isOpen || isScrolled ? "text-slate-100" : "text-slate-100"
            } text-sm rounded-lg md:hidden`}
            ref={closeButtonRef}
          >
            {isOpen ? (
              <svg
                className="w-7 h-7"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="white"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            ) : (
              <svg
                className="w-7 h-7"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M4 6h16M4 12h16M4 18h16"
                />
              </svg>
            )}
          </button>
        </div>
      </div>
      <div
        ref={menuRef}
        className={`fixed top-0 right-0 w-3/4 h-screen bg-black z-50 transform transition-transform ${
          isOpen ? "translate-x-0" : "translate-x-full"
        }`}
      >
        <button
          onClick={toggleMenu}
          type="button"
          className="absolute top-5 right-5 text-white"
          ref={closeButtonRef}
        >
          <svg
            className="w-7 h-7"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="white"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        </button>
        <div className="h-full flex flex-col items-center justify-center">
          <ul className="flex flex-col p-8 space-y-4 text-2xl font-medium text-white">
            <li className="my-1">
              <Hlink smooth to="/#how-it-works">
                <a onClick={toggleMenu}>How it works?</a>
              </Hlink>
            </li>
            <li className="my-1">
              <Hlink smooth to="/#reviews">
                <a onClick={toggleMenu}>Reviews</a>
              </Hlink>
            </li>
            <li className="my-1">
              <Hlink smooth to="/#faq">
                <a onClick={toggleMenu}>FAQ</a>
              </Hlink>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
}

export default Navbar;

import { useState, useEffect, useRef } from "react";
// import earth from "../media/earth_cmp.mp4";
import { FiArrowUpRight } from "react-icons/fi"; // Import the arrow icon
import { Link } from "react-router-dom";
import { ShootingStars } from "../Components/ui/shooting-stars";
import { StarsBackground } from "../Components/ui/stars-background";
import Chrome from "../media/getonchrome.jpg";

function Main() {
  const [scrollY, setScrollY] = useState(0);

  // Function to handle scroll event
  const handleScroll = () => {
    setScrollY(window.scrollY);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const videoRef = useRef(null);

  useEffect(() => {
    if (videoRef.current) {
      // Set the playback rate to slow down the video
      videoRef.current.playbackRate = 0.7; // 0.5 is half speed; 1 is normal speed
    }
  }, []);

  // Calculate horizontal translation based on scroll position
  const translateX = Math.max(-100, scrollY * 2); // Translate left as you scroll down

  return (
    <section className="top-0 left-0 h-[90vh] overflow-hidden ">
      <div
        style={{
          position: "relative",
          width: "100vw", // Full width of the viewport
          height: "100vh", // Full height of the viewport
          overflow: "hidden", // Hide the extra part
        }}
      >
        {/* Black fade overlay for the video */}
        <div className="absolute bottom-0 left-0 right-0 h-1/6 bg-gradient-to-t from-black to-transparent" />

        <div className="flex flex-col justify-center items-center bg-custom bg-center bg-no-repeat bg-cover px-4 mx-auto max-w-screen-3xl text-center w-screen h-screen py-24 lg:py-56 relative z-20">
          <h1
            className="mix-blend-exclusion text-[18vw] mb-4 font-extrabold tracking-widest leading-none text-white sm:text-8xl md:text-8xl lg:text-9xl"
            style={{
              transform: `translateX(${translateX}px)`,
              transition: "transform 0.1s ease-out", // Smooth transition for better effect
            }}
          >
            ProdNx
          </h1>
          {/* <h1
            className="mix-blend-exclusion mb-4 font-extrabold tracking-widest leading-none text-white text-[10vw] sm:text-[5vmax] lg:text-5xl"
            style={{
              transform: `translateX(-${translateX}px)`,
              transition: "transform 0.1s ease-out", // Smooth transition for better effect
            }}
          >
            N times your Productivty
          </h1> */}
          {/* <br /> */}
          <h4
            className="text-xl text-white"
            style={{
              transform: `translateX(-${translateX}px)`,
              transition: "transform 0.1s ease-out", // Smooth transition for better effect
            }}
          >
            {/* GO FROM{" "} */}
            <span className="font-bold text-transparent bg-clip-text bg-gradient-to-r from-[#bdcf32] to-[#5c8500]">
              N TIMES YOUR PRODUCTIVITY!
            </span>{" "}
            {/* your Productivty */}
          </h4>
          {/* <br /> */}
          <br />
          <button
            type="button"
            className="relative group flex text-lg items-center justify-start focus:outline-none font-medium rounded-10  space-x-2
              bg-gradient-to-br bg-white text-white z-20"
            style={{
              transform: `translateX(${translateX}px)`,
              transition: "transform 0.1s ease-out", // Smooth transition for better effect
            }}
          >
            <a
              href="https://chromewebstore.google.com/detail/productivity-tracker/ihfjboopehfdmedfagockddklomdmglh"
              className=" flex items-center  space-x-3 rtl:space-x-reverse"
            >
              {/* <span className="font-bold text-4xl pb-[4px]">Starter</span> */}
              <img
                src={Chrome} // Use the imported logo here
                alt="Starter 101 Logo"
                className="w-48 h-12" // Adjust size as needed
              />
            </a>
            {/* Available on Chrome Web Store */}
          </button>
        </div>
      </div>
    </section>
  );
}

export default Main;

// import img from "next/img";
import React from "react";
import { Timeline } from "../Components/ui/timeline.jsx";
import { Link } from "react-router-dom";

export default function TimelineDemo() {
  const data = [
    {
      title: "Step 1",
      content: (
        <div>
          Go to{" "}
          <a href="https://chromewebstore.google.com/detail/productivity-tracker/ihfjboopehfdmedfagockddklomdmglh">
            <strong className="text-transparent bg-clip-text bg-gradient-to-r from-[#bdcf32] to-[#5c8500]">
              Chrome Web Store
            </strong>
          </a>
        </div>
      ),
    },
    {
      title: "Step 2",
      content: (
        <div>
          Click on{" "}
          <strong className="text-transparent bg-clip-text bg-gradient-to-r from-[#bdcf32] to-[#5c8500]">
            Get
          </strong>{" "}
          or{" "}
          <strong className="text-transparent bg-clip-text bg-gradient-to-r from-[#bdcf32] to-[#5c8500]">
            Add to Chrome
          </strong>
        </div>
      ),
    },
    {
      title: "Step 3",
      content: (
        <div>
          Accept the{" "}
          <strong className="text-transparent bg-clip-text bg-gradient-to-r from-[#bdcf32] to-[#5c8500]">
            Permission
          </strong>{" "}
          to read browser history and click{" "}
          <strong className="text-transparent bg-clip-text bg-gradient-to-r from-[#bdcf32] to-[#5c8500]">
            Add Extention.
          </strong>
          <br />
          (PS: To function properly it needs to read your browser history. Be
          assured, your data remains stored in your browser only and all of it
          will be lost permanently if you uninstall the extention.)
        </div>
      ),
    },
    {
      title: "Step 4",
      content: (
        <div>
          Go to extentions on top right of browser and{" "}
          <strong className="text-transparent bg-clip-text bg-gradient-to-r from-[#bdcf32] to-[#5c8500]">
            PIN{" "}
          </strong>
          the extention.
        </div>
      ),
    },
    {
      title: "Step 5",
      content: (
        <div>
          Anytime click on the extention icon and see the{" "}
          <strong className="text-transparent bg-clip-text bg-gradient-to-r from-[#bdcf32] to-[#5c8500]">
            Tracking Details
          </strong>
          .
        </div>
      ),
    },
    {
      title: "Step 6",
      content: (
        <div>
          Incase you want to{" "}
          <strong className="text-transparent bg-clip-text bg-gradient-to-r from-[#bdcf32] to-[#5c8500]">
            Disable
          </strong>{" "}
          the extention temporarily, you can right click on that and select
          manage extenion and turn it off.
        </div>
      ),
    },
    {
      title: "Step 7",
      content: (
        <div>
          By default, the extenion won't track anything in{" "}
          <strong className="text-transparent bg-clip-text bg-gradient-to-r from-[#bdcf32] to-[#5c8500]">
            Incognito
          </strong>{" "}
          mode but same can be enabled in the manage extention settings.
        </div>
      ),
    },
    {
      title: "Step 8",
      content: (
        <div>
          Hopefully, this extention will help you increase your productivity!
          <br />
          <strong className="text-transparent bg-clip-text bg-gradient-to-r from-[#bdcf32] to-[#5c8500]">
            ThankYou for choosing us :)
          </strong>
        </div>
      ),
    },
  ];

  return (
    <div className="w-full bg-black mt-20 bg-opacity-50" id="how-it-works">
      <Timeline data={data} />
    </div>
  );
}

import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";
import { BackgroundGradient } from "../Components/ui/background-gradient";

const FAQSection = () => {
  const [openIndex, setOpenIndex] = useState(null);

  const [india, setIndia] = useState(false);
  // console.log("india", india);

  useEffect(() => {
    if (Intl.DateTimeFormat().resolvedOptions().timeZone == "Asia/Calcutta") {
      setIndia(true);
      // console.log("india", india);
    }
  });

  const faqs = [
    {
      question: "Is there any cost involved?",
      answer: (
        <>
          No! This extension is completely{" "}
          <strong className="text-transparent bg-clip-text bg-gradient-to-r from-[#bdcf32] to-[#5c8500]">
            FREE
          </strong>{" "}
          for everyone to use.
        </>
      ),
    },
    {
      question: "Do you sell my data?",
      answer: (
        <>
          <strong className="text-transparent bg-clip-text bg-gradient-to-r from-[#bdcf32] to-[#5c8500]">
            Absolutely NOT!
          </strong>{" "}
          We don’t collect any of your data, let alone sell it.
        </>
      ),
    },
    {
      question: "What is personalized tracking?",
      answer: (
        <>
          <strong className="text-transparent bg-clip-text bg-gradient-to-r from-[#bdcf32] to-[#5c8500]">
            Personalized Tracking
          </strong>{" "}
          lets you easily categorize sites as productive or non-productive with
          a single click. You’ll get a breakdown of your total productive and
          non-productive time, along with a visual chart for better tracking. We
          believe this feature will help you stay focused and boost your
          productivity.{" "}
          <strong className="text-transparent bg-clip-text bg-gradient-to-r from-[#bdcf32] to-[#5c8500]">
            Keep Hustling!
          </strong>
        </>
      ),
    },
    {
      question: "How do you earn from this?",
      answer: (
        <>
          <strong className="text-transparent bg-clip-text bg-gradient-to-r from-[#bdcf32] to-[#5c8500]">
            I DON'T EARN
          </strong>{" "}
          from this project. It's my way of contributing to society as a new
          software developer. I also plan to make it open-source soon.{" "}
          <strong className="text-transparent bg-clip-text bg-gradient-to-r from-[#bdcf32] to-[#5c8500]">
            If you know of any developer job opportunities,
          </strong>{" "}
          feel free to reach out to me via{" "}
          <strong className="text-transparent bg-clip-text !text-md md:!text-2xl bg-gradient-to-r from-[#bdcf32] to-[#5c8500]">
            <a href="mailto:setia.lakshay@outlook.com">
              setia.lakshay@outlook.com
            </a>
          </strong>{" "}
          Thanks :)
        </>
      ),
    },
    {
      question: "Where is my data stored?",
      answer: (
        <>
          Your data is{" "}
          <strong className="text-transparent bg-clip-text bg-gradient-to-r from-[#bdcf32] to-[#5c8500]">
            securely stored locally
          </strong>{" "}
          on your browser and is as safe as your browser history.
        </>
      ),
    },
    {
      question: "How can I delete my data?",
      answer: (
        <>
          Simply{" "}
          <strong className="text-transparent bg-clip-text bg-gradient-to-r from-[#bdcf32] to-[#5c8500]">
            remove the extension,
          </strong>{" "}
          and all your data will be deleted. If you want to start fresh, just
          reinstall the extension. (PS: We're working on a feature that will
          allow you to delete data without uninstalling the extension. Stay
          tuned!)
        </>
      ),
    },
    {
      question:
        "Can I recover my data if I accidentally removed the extension?",
      answer: (
        <>
          Unfortunately, once the extension is removed, your{" "}
          <strong className="text-transparent bg-clip-text bg-gradient-to-r from-[#bdcf32] to-[#5c8500]">
            data is lost{" "}
          </strong>
          because it is stored locally on your browser.
        </>
      ),
    },
    {
      question: "Can I sync my data across multiple devices?",
      answer: (
        <>
          Currently, syncing data across devices{" "}
          <strong className="text-transparent bg-clip-text bg-gradient-to-r from-[#bdcf32] to-[#5c8500]">
            isn’t possible.
          </strong>{" "}
          Adding that feature would require server storage, which could lead to
          additional costs and data storage concerns. For now,{" "}
          <strong className="text-transparent bg-clip-text bg-gradient-to-r from-[#bdcf32] to-[#5c8500]">
            we prioritize your data privacy and security,
          </strong>{" "}
          and that's why we’ve chosen to keep everything local. Thank you for
          your understanding!
        </>
      ),
    },
    {
      question: "Can I download my data?",
      answer: (
        <>
          Downloading your data isn't available at the moment, but don't worry —{" "}
          <strong className="text-transparent bg-clip-text bg-gradient-to-r from-[#bdcf32] to-[#5c8500]">
            we’re working
          </strong>{" "}
          on adding this feature very soon!
        </>
      ),
    },
  ];

  const toggleFAQ = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  const [showMore, setShowMore] = useState(false);

  const displayedFAQs = showMore ? faqs : faqs.slice(0, 5); // Show only the first 5 FAQs by default

  return (
    <section
      id="faq"
      className="top-full min-h-fit w-screen "
      style={{
        position: "relative",
        width: "100%",
        overflow: "hidden",
        transition: "transform 0.5s ease-out",
      }}
    >
      <div className="w-screen h-full p-10 sm:p-20">
        <h2 className="text-5xl md:text-6xl font-bold text-stone-100 text-center mb-4 drop-shadow-[0_2.2px_2.2px_rgba(0,0,0,0.9)]">
          Frequently Asked Questions
        </h2>
        <h4 className="text-lg text-white mb-10 text-center">
          <span className="font-bold text-transparent bg-clip-text bg-gradient-to-r from-[#bdcf32] to-[#5c8500]">
            CURIOUS MINDS, WE'VE GOT YOU COVERED!
          </span>{" "}
        </h4>

        <div className="space-y-4">
          {displayedFAQs.map((faq, index) => (
            // <BackgroundGradient className="rounded-[22px] p-1 bg-black">
            <div
              key={index}
              className="relative bg-black bg-opacity-75 rounded-lg shadow-2xl p-4 transition duration-500 ease-in-out "
              style={{
                boxShadow: `
                0px 0px 40px #68970057
                `,
              }}
            >
              <div
                className="flex items-center cursor-pointer"
                onClick={() => toggleFAQ(index)}
              >
                <span className="h-fit text-stone-100 text-4xl font-bold mr-4">
                  {openIndex === index ? "-" : "+"}
                </span>
                <h3 className="text-2xl text-stone-100 font-semibold">
                  {faq.question}
                </h3>
              </div>
              {openIndex === index && (
                <p className="mt-2 ml-7 text-2xl text-stone-100">
                  {faq.answer}
                </p>
              )}
            </div>
            // </BackgroundGradient>
          ))}
        </div>
        <div className="flex justify-center mt-6">
          <button
            className="flex items-center text-white border border-white rounded px-4 py-2 transition duration-300 hover:bg-gradient-to-r  hover:text-md"
            onClick={() => setShowMore(!showMore)}
          >
            {showMore ? (
              <>
                <FaChevronUp className="mr-2" />
                Show Less
              </>
            ) : (
              <>
                <FaChevronDown className="mr-2" />
                Show More
              </>
            )}
          </button>
        </div>
      </div>
    </section>
  );
};

export default FAQSection;

import React, { useEffect, useRef, useState } from "react";
import { CountUp } from "countup.js"; // Correct named import

// Update component to accept props
export default function Home({ endValue }) {
  const [isInView, setIsInView] = useState(false);
  const countupRef = useRef(null);
  const countUpAnim = useRef(null);

  // Initialize the CountUp animation with the endValue prop
  const initCountUp = () => {
    if (countupRef.current) {
      countUpAnim.current = new CountUp(countupRef.current, endValue); // Use endValue prop
      if (!countUpAnim.current.error) {
        countUpAnim.current.start();
      } else {
        console.error(countUpAnim.current.error);
      }
    }
  };

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsInView(true);
        }
      },
      { threshold: 0.5 } // Adjust this value as needed
    );

    if (countupRef.current) {
      observer.observe(countupRef.current);
    }

    return () => {
      if (countupRef.current) {
        observer.unobserve(countupRef.current);
      }
    };
  }, []);

  useEffect(() => {
    if (isInView) {
      initCountUp();
    }
  }, [isInView]);

  return (
    <>
      <span ref={countupRef}>0</span>
    </>
  );
}

"use client";
import { useScroll, useTransform, motion } from "framer-motion";
import { useEffect, useRef, useState } from "react";
import { ShootingStars } from "./shooting-stars";
import { StarsBackground } from "./stars-background";

export const Timeline = ({ data }) => {
  const ref = useRef(null);
  const containerRef = useRef(null);
  const [height, setHeight] = useState(0);

  useEffect(() => {
    if (ref.current) {
      const rect = ref.current.getBoundingClientRect();
      setHeight(rect.height);
    }
  }, [ref]);

  const { scrollYProgress } = useScroll({
    target: containerRef,
    offset: ["start 10%", "end 50%"],
  });

  const heightTransform = useTransform(scrollYProgress, [0, 1], [0, height]);
  const opacityTransform = useTransform(scrollYProgress, [0, 0.1], [0, 1]);

  return (
    <>
      <div
        className="w-full font-sans md:px-10"
        ref={containerRef}
        style={{
          position: "relative",
          width: "100vw", // Full width of the viewport
          //   height: "100vh", // Full height of the viewport
          overflow: "hidden", // Hide the extra part
        }}
      >
        {/* Set z-index to -1 for ShootingStars and StarsBackground */}
        {/* <ShootingStars />
        <StarsBackground /> */}

        {/* <div className="max-w-7xl mx-auto py-20 px-4 md:px-8 lg:px-10">
        <h2 className="text-lg md:text-4xl mb-4 text-white max-w-4xl">
          Changelog from my journey
        </h2>
        <p className="text-neutral-300 text-sm md:text-base max-w-sm">
          I&apos;ve been working on Aceternity for the past 2 years. Here&apos;s
          a timeline of my journey.
        </p>
      </div> */}
        <div className="flex flex-col justify-center items-center  z-10">
          <h1 className="text-4xl sm:text-5xl md:text-6xl font-bold pb-4 text-white drop-shadow-[0_1.2px_1.2px_rgba(0,0,0,0.8)]">
            How it WORKS?
          </h1>
        </div>
        <div ref={ref} className="relative max-w-2xl mx-auto pb-20">
          {data.map((item, index) => (
            <div key={index} className="flex justify-center pt-10 md:pt-10 ">
              <div className="sticky flex flex-col md:flex-row z-40 items-center top-40  max-w-xs lg:max-w-sm md:w-fit">
                <div className="h-10 absolute left-3 md:left-3 w-10 rounded-full bg-black flex items-center justify-center">
                  <div className="h-4 w-4 rounded-full bg-neutral-800 border border-neutral-700 p-2" />
                </div>
                <h3 className="hidden md:block text-xl md:pl-16 md:text-3xl font-bold text-neutral-100 whitespace-nowrap mr-4">
                  {item.title}
                </h3>
              </div>

              <div className="text-neutral-200 relative pl-20 pr-4 md:pl-4  text-xl md:text-2xl w-full">
                {/* <h3 className="md:hidden block text-2xl mb-4 text-left font-bold text-neutral-500">
                {item.title}
              </h3> */}
                {item.content}{" "}
              </div>
            </div>
          ))}
          <div
            style={{
              height: height + "px",
            }}
            className="absolute md:left-8 left-8 top-0 overflow-hidden w-[2px] bg-[linear-gradient(to_bottom,var(--tw-gradient-stops))] from-transparent from-[0%] via-neutral-700 to-transparent to-[99%]  [mask-image:linear-gradient(to_bottom,transparent_0%,black_10%,black_90%,transparent_100%)] "
          >
            <motion.div
              style={{
                height: heightTransform,
                opacity: opacityTransform,
              }}
              className="absolute inset-x-0 top-0  w-[2px] bg-gradient-to-t from-[#bdcf32] to-[#5c8500] from-[0%] via-[10%] rounded-full"
            />
          </div>
        </div>
      </div>
    </>
  );
};

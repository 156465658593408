import React from "react";

const StepFlow = ({ steps }) => {
  return (
    <div className="grid grid-cols-1 gap-4 px-4 py-12 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
      {steps.map((step, index) => (
        <div
          key={index}
          className="relative flex flex-col items-center mb-8"
          style={{
            minHeight: "10rem",
            minWidth: "18rem",
            maxWidth: "18rem",
          }}
        >
          {/* Step Box with Frosted Glass Effect */}
          <div className="step-box w-full">
            {/* Step Title */}
            <h2 className="text-3xl font-bold mb-2 text-center text-cyan-200">
              Step {index + 1}
            </h2>
            {/* Step Description with Bold Words */}
            <p className="text-white text-2xl text-center">
              {step.description}
            </p>
          </div>
        </div>
      ))}
    </div>
  );
};

// Example usage of the StepFlow component
const App = () => {
  const steps = [
    {
      description: (
        <>
          Click on <strong>Get Started</strong>
        </>
      ),
    },
    {
      description: (
        <>
          Fill your details in <strong>Registration Form</strong>
        </>
      ),
    },
    {
      description: (
        <>
          Select suitable time-slot for <strong>Discovery Call</strong>
        </>
      ),
    },
    {
      description: (
        <>
          We meet to <strong>Discuss</strong> the process and{" "}
          <strong>Requirements</strong>
        </>
      ),
    },
    {
      description: (
        <>
          We create a <strong>Personalized Path</strong> to get the best
          experience
        </>
      ),
    },
    {
      description: (
        <>
          Choose the class <strong>Timings</strong>
        </>
      ),
    },
    { description: <>Pay the fees (bill will be sent to email via PayPal)</> },
    { description: <>Robotics Classes Starts!</> },
  ];

  return (
    <section
      id="how-it-works"
      className="top-full bg-green-500 min-h-screen flex justify-center relative custom-section"
      style={{
        width: "100%",
        overflow: "hidden",
        transition: "transform 0.5s ease-out",
        // backgroundImage: `url(${Steps})`,
        // backgroundSize: "cover",
        // backgroundPosition: "center",
        // backgroundRepeat: "no-repeat",
      }}
    >
      <div className="absolute inset-0 bg-black opacity-50  custom-overlay"></div>
      <div className="flex flex-col justify-center items-center pt-20 z-10">
        <h1
          className="text-5xl sm:text-6xl md:text-7xl font-bold text-white mb-12 drop-shadow-[0_1.2px_1.2px_rgba(0,0,0,0.8)]
"
        >
          How it WORKS?
        </h1>
        <StepFlow steps={steps} />
      </div>
    </section>
  );
};

// CSS styles for 3D hover effect
const styles = `
.step-box {
  padding: 1rem;
  background: rgba(255, 255, 255, 0.2);
  backdrop-filter: blur(10px);
  border-radius: 1rem;
  box-shadow: 0 8px 32px 0 rgba(0, 0, 0, 0.37);
  border: 1px solid rgba(255, 255, 255, 0.18);
  transition: transform 0.3s, box-shadow 0.3s;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.step-box:hover {
  transform: scale(1.05) rotateY(5deg);
  box-shadow: 0 12px 40px 0 rgba(0, 0, 0, 0.5);
}
`;

const styleSheet = document.createElement("style");
styleSheet.type = "text/css";
styleSheet.innerText = styles;
document.head.appendChild(styleSheet);

export default App;
